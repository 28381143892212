









































































import { Component, Vue, Watch } from "vue-property-decorator";
import { checkPhone } from "../utils/validate";
import { UserModule } from "@/store/modules/user";
import { Route } from "vue-router";

@Component({
	name: "login"
})
export default class Login extends Vue {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	$refs: { [key: string]: any } = {
		phoneForm: HTMLInputElement,
		passwordForm: HTMLInputElement
	};
	private formData = {
		mobile: "",
		password: ""
	};
	private active = 0;
	private isPsw = true;
	private loginLoading = false;
	private redirect: string | undefined = undefined;
	@Watch("$route", { immediate: true })
	private OnRouteChange(route: Route) {
		// TODO: remove the "as string" hack after v4 release for vue-router
		// See https://github.com/vuejs/vue-router/pull/2050 for details
		this.redirect = route.query && (route.query.redirect as string);
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	private onFocus(name: any, active: number) {
		this.$nextTick(() => {
			console.log(this.$refs);
			this.$refs[name].focus();
			this.active = active;
		});
	}

	// 验证手机号
	private checkphone() {
		if (!this.formData.mobile || !checkPhone(this.formData.mobile))
			return this.$message.error("请输入并正确手机号!");
	}

	// 登录
	private onLogin() {
		const { password } = this.formData;
		this.loginLoading = true;
		if (!password) return this.$message.error("请输入密码!");
		UserModule.Login(this.formData).then((res: any) => {
			console.log(res);
			if (res.code == 0) {
				if (res.data.has_company) {
					this.$router.push({ path: this.redirect || "/" });
				} else {
					this.$router.push({ path: "/userInfo" });
				}
			}
			this.loginLoading = false;
		});
	}
}
